import { createApp, defineAsyncComponent } from "vue";

import SvgVue from 'svg-vue3'
import VueLazyLoad from 'vue3-lazyload'
const VueScrollTo = require('vue-scrollto')

const app = createApp({
  components: {

    Slider: defineAsyncComponent(() => import('./common/Slider.vue')),
    SocialIcons: defineAsyncComponent(() => import('./common/SocialMediaIcons.vue')),
    ContactForm: defineAsyncComponent(() => import('./common/ContactForm.vue')),
    EmploymentForm: defineAsyncComponent(() => import('./common/EmploymentForm.vue')),

    PhotoGallery: defineAsyncComponent(() => import('./blocks/PhotoGallery.vue')),
    AccordionList: defineAsyncComponent(() => import('./blocks/AccordionList.vue')),
    AccordionPanels: defineAsyncComponent(() => import('./blocks/AccordionPanels.vue')),
    TabBox: defineAsyncComponent(() => import('./blocks/TabBox.vue')),

  },

  data: () => ({
    openMenu: '',
    scrollPosition: 0,
    activeSideBarNav: 'nothing',
    alertsOpen: false,
    
  }),

  computed: {
    mobileMenu () {
      return this.openMenu === 'mobileMenu'
    },
    howDoI () {
      return this.openMenu === 'howDoI'
    },
    searchBox () {
      return this.openMenu === 'searchBox'
    },
    isScrolling () {
      return this.scrollPosition > 0
    },
  },

  mounted () {
    if (window.location.hash.substring(0, 1) === "#") {
      VueScrollTo.scrollTo(window.location.hash, 300, {
        easing: 'ease-out',
        offset: -100,
        force: true,
        cancelable: true,
      })
    }
  },

  methods: {
    toggleMenu (menu) {
      this.openMenu = (this.openMenu != menu ? menu : '')
    },

    handleScroll (event) {
      this.scrollPosition = window.pageYOffset != undefined ? window.pageYOffset : window.scrollY
    },

    scrollTo ( to, duration = 200, offset = -100) {
      VueScrollTo.scrollTo(to, duration, {
        easing: 'ease-out',
        offset: offset,
        force: true,
        cancelable: true,
      })
    },

    setNavItem(val) {
      this.activeSideBarNav = val
    },

  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

})

app.use(VueLazyLoad)
app.use(SvgVue, {
  svgPath: 'resources/svg',
  extract: false,
  svgoSettings: [
      { removeTitle: true },
      { removeViewBox: false },
      { removeDimensions: true }
  ]
})

app.mount("#app")
